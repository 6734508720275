//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TriangleCustomRate from '$resources/images/triangle-custom-rate.png';

export default {
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    selected: Boolean,
    offer: Object,
    hiredPower: String,
    variant: {
      type: String,
      validator: v => ['common', 'without-wattage'].includes(v),
      default: 'common'
    },
    steps: Object
  },
  data: () => ({
    TriangleCustomRate
  }),
  computed: {
    ratesDescription() {
      switch (this.offer.idTarifaPersonalizada) {
        case '1':
          return [
            'Paga solo por lo que has utilizado cada mes, con un precio ajustado.',
            'La mejor tarifa que permite aprovechar las horas con menos coste, para tu suministro menor de 15 Kw.'
          ];
        case '43':
          return [
            'Paga solo por lo que has utilizado cada mes.',
            'Ahorra pagando solo por lo que necesitas.',
            'La mejor tarifa que te permite aprovechar las horas con menor coste, para tu suministro menor de 15 Kw, con un mínimo coste de gestión.'
          ];
        case '44':
          return [
            'Paga solo por lo que has utilizado cada mes.',
            'Ahorra pagando solo por lo que necesitas.',
            'La mejor tarifa que te permite aprovechar las horas con menor coste, para tu suministro mayor 15 Kw, con un mínimo coste de gestión.'
          ];
        case '45':
          return [
            'Paga solo por lo que has utilizado cada mes.',
            'Ahorra pagando solo por lo que necesitas.',
            'La mejor tarifa que te permite aprovechar las horas con menor coste, para tu suministro de alta tensión, con un mínimo coste de gestión.'
          ];
        default:
          return [];
      }
    },
    tarifaNameFormated() {
      if (this.offer.aliasTarifa) {
        return this.offer.aliasTarifa;
      }

      return this.offer.nombreTarifa;
    },
    priceMontlyClassName() {
      return {
        alone: this.offer.ahorroMensual === null,
        small: this.offer.precioMensual
      };
    },
    savingsMontlyClassName() {
      return {
        small: String(this.offer.ahorroMensual.toFixed(2)).length > 5
      };
    },
    computedFeeEnergia() {
      const tempEnergia = Object.values(this.offer.feeEnergiaSelected);
      return tempEnergia.some(fee => fee !== null);
    },
    computedTextFeeEnergia() {
      const tempEnergia = Object.values(this.offer.feeEnergiaSelected);
      return tempEnergia
        .map((fee, index) => {
          if (fee !== null) {
            return `P${index + 1}: ${fee}`;
          }
          return null;
        })
        .filter(fee => fee !== null)
        .join(' ');
    },
    computedFeeAutoconsumo() {
      const tempEnergia = Object.values(this.offer.feeAutoconsumoSelected);
      return tempEnergia.some(fee => fee !== null);
    },
    computedTextAutoconsumo() {
      const tempAutoconsumo = Object.values(this.offer.feeAutoconsumoSelected);
      return tempAutoconsumo
        .map((fee, index) => {
          if (fee !== null) {
            return `P${index + 1}: ${fee}`;
          }
          return null;
        })
        .filter(fee => fee !== null)
        .join(' ');
    },
    computedFeePotencia() {
      const tempEnergia = Object.values(this.offer.feePotenciaSelected);
      return tempEnergia.some(fee => fee !== null);
    },
    computedTextFeePotencia() {
      const tempPotencia = Object.values(this.offer.feePotenciaSelected);
      return tempPotencia
        .map((fee, index) => {
          if (fee !== null) {
            return `P${index + 1}: ${fee}`;
          }
          return null;
        })
        .filter(fee => fee !== null)
        .join(' ');
    }
  }
};
